@import "../../utils/all";

@include critical {
  .zone.zone--more-in-life {
    position: relative;
    padding-top: 35px;
    padding-bottom: 30px;
    @include respond-above(phone) {
      padding-top: 55px;
      padding-bottom: 40px;
    }
    @include respond-above(sm-tablet) {
      padding-top: 65px;
      padding-bottom: 50px;
    }
    @include respond-above(bg-tablet) {
      padding-top: 35px;
      padding-bottom: 55px;
    }
    @include respond-above(bg-desktop) {
      padding-bottom: 90px;
    }
    @include respond-above(max) {
      padding-top: 60px;
      padding-bottom: 110px;
    }
    position: relative;
    // this is the triangle background
    &.sticky:after {
      position: fixed;
      @include respond-above(bg-tablet) {
        position: absolute;
      }
    }
    &.sticky-bottom:after {
      top: initial;
      bottom: 0;
      @include respond-above(bg-tablet) {
        top: 0;
        bottom: initial;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 569px;
      z-index: -1;
      background-color: $really-light-blue;
      -webkit-clip-path: polygon(0 0, 0% 100%, calc(100% - 50px) 0);
      clip-path: polygon(0 0, 0% 100%, calc(100% - 50px)  0);
      @include respond-above(phone) {
        height: 667px;;
        -webkit-clip-path: polygon(0 0, 0% 100%, calc(100% - 55px) 0);
        clip-path: polygon(0 0, 0% 100%, calc(100% - 55px)  0);
      }
      @include respond-above(sm-tablet) {
        height: 736px;
        -webkit-clip-path: polygon(0 0, 0% 100%, calc(100% - 60px) 0);
        clip-path: polygon(0 0, 0% 100%, calc(100% - 55px)  0);
      }
      @include respond-above(bg-tablet) {
        height: 515px;
        -webkit-clip-path: polygon(0 0, 0% 100%, 641px 0);
        clip-path: polygon(0 0, 0% 100%, 641px 0);
      }
      @include respond-above(sm-desktop) {
        height: 100%;
        -webkit-clip-path: polygon(0 0, 0% 100%, 814px 0);
        clip-path: polygon(0 0, 0% 100%, 814px 0);
      }
      @include respond-above(bg-desktop) {
        -webkit-clip-path: polygon(0 0, 0% 100%, 962px 0);
        clip-path: polygon(0 0, 0% 100%, 962px 0);
      }
      @include respond-above(max) {
        -webkit-clip-path: polygon(0 0, calc(50% + 145px) 0, calc(50% - 640px) 100%, 0% 100%);
        clip-path: polygon(0 0, calc(50% + 145px) 0, calc(50% - 640px) 100%, 0% 100%);
      }
    }
    .zone {
      &__title {
        @extend %h2-entry-life;
      }
      &__content {
        display: flex;
        flex-direction: column;
        @include respond-above(bg-tablet) {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
      &__title,
      &__content {
        max-width: calc(100% - 60px);
        @include set-container-max;
        @include respond-above(sm-desktop) {
          max-width: 969px;
        }
        @include respond-above(bg-desktop) {
          max-width: 1120px;
        }
      }
    }
    .card {
      margin-bottom: 30px;
      @include respond-above(bg-tablet) {
        width: calc(50% - 15px);
        &:nth-child(odd) {
          margin-right: 30px;
        }
      }
      @include respond-above(sm-desktop) {
        width: calc(25% - 22.5px);
        &:nth-child(odd) {
          margin-right: 0;
        }
        &:not(:nth-child(4n+4)) {
          margin-right: 30px;
        }
      }
      &__headline {
        text-align: center;
        color: black;
        text-decoration: none;
        &__text {
          font-family: Montserrat, sans-serif;
          font-size: rem-calc(20px);
          font-weight: bold;
          line-height: 1.3;
        }
      }
      &__label--bottom {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}

@include non-amp {
  .entry.ab-hp_outbrain_remove_test {
    &--variant2,
    &--variant3 {
      @include respond-above(bg-tablet) {
        .zone.zone--more-in-life {
          .zone__content {
            .card {
              width: calc(33.33% - ((2/3)* 25px));
              margin-right: 25px;
              &:nth-child(3),
              &:nth-child(4n+3){
                margin-right: 0;
              }
            }
          }
        }
        &.no-ads {
          .zone--more-in-life {
            .zone__content {
              .card {
                margin-right: 25px;
                &:nth-child(4n+3) {
                  margin-right: 25px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
